import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import { MdDelete } from "react-icons/md";

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";


// Import for the searchable combo box.
import Select from 'react-select';

export default function FrmGoodsReturnEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName } = configConstants;

    const { state } = useLocation();
    const { keyForViewUpdate, goodsReturnMasterId, compType } = state || {};
    const [action_Label, setActionLabel] = useState('(Creation)');
    const [isLoading, setIsLoading] = useState(false);
    const [showAddUpdateBtn, setShowAddUpdateBtn] = useState(false)

    //For navigate
    const navigate = useNavigate();

    //UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();

    //Data states
    const [GrnDetails, setGrnDetails] = useState([])
    const [grnOptions, setGRNOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [jobTypeOptions, setJobTypeArr] = useState([]);


    //combo options
    const [cmb_grn_no, setGRNno] = useState("");
    const [cmb_sales_job_type, setSalesOrderJob] = useState("");
    const [cmb_supplier_id, setSupplier_id] = useState("");

    //Form Fields
    const [cmb_material_type, setmaterialType] = useState('');
    const [txt_goods_return_no, setGoodsReturnNo] = useState(0);
    const [txt_goods_return_weight, setGoodsReturnWeight] = useState(0);
    const [txt_goods_return_quantity, setGoodsReturnQuantity] = useState('');
    const [txt_goods_return_boxes, setGoodsReturnBoxes] = useState('');
    const [txt_material_rate, setMaterialRate] = useState('');
    const [txt_goods_return_version, setGoodsReturnVersion] = useState(1);

    const [inputValues, setInputValues] = useState(

        GrnDetails.reduce((acc, _, index) => {
            acc[index] = {
                return_quantity: 0,
                return_weight: 0,
                return_boxes: 0,
                return_rate: 0,
            };
            return acc;
        }, {})
    );

    const handleInputChange = (rowIndex, field, value) => {
        const grnDetail = GrnDetails[rowIndex];

        const numericValue = parseFloat(value) || 0;

        let isValid = true;
        let errorMessage = "";

        switch (field) {
            case "return_quantity":
                if (numericValue > grnDetail.product_material_grn_accepted_quantity || numericValue > grnDetail.closing_balance_quantity) {
                    isValid = false;
                    errorMessage = "Enter valid quantity.";
                }
                break;
            case "return_boxes":
                if (numericValue > grnDetail.no_of_boxes || numericValue > grnDetail.closing_no_of_boxes) {
                    isValid = false;
                    errorMessage = "Enter valid number of boxes.";
                }
                break;
            // case "return_rate":
            //     if (numericValue > grnDetail.material_rate) {
            //         isValid = false;
            //         errorMessage = "Enter valid rate.";
            //     }
            //     break;
            default:
                break;
        }

        const errorElementId = `#error_${field}_${rowIndex}`;
        if (!isValid) {
            $(errorElementId).text(errorMessage).show();
            return; // Exit without updating the state
        } else {
            $(errorElementId).hide();
        }

        setInputValues((prevState) => {
            const updatedRow = {
                ...prevState[rowIndex],
                [field]: value,
                ...(field === "return_quantity"
                    ? { return_weight: numericValue * grnDetail.weight_per_box_item }
                    : {}),
            };

            return {
                ...prevState,
                [rowIndex]: updatedRow,
            };
        });
    };



    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const clearFields = () => {
        setGoodsReturnBoxes(0)
        setGoodsReturnWeight(0)
        setGoodsReturnQuantity(0)
    }

    const [dt_goods_return_date, setGoodsReturnDate] = useState(todayDate);

    useEffect(async () => {
        setIsLoading(true);
        try {
            await fillComobos();
            ActionType()
            if (goodsReturnMasterId !== 0) {
                await FnCheckUpdateResponce();
            }
            // FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])
    // delete popup
    const [show, setShow] = useState(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const currentDate = new Date();

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate('/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing')
        }
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionLabel('(Update)')
                break;
            case 'view':
                setActionLabel('(View)')
                // await validate.current.readOnly("moduleFormId");
                break;
            default:
                setActionLabel('(Creation)')
                break;
        }
    };

    const fillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setmaterialtypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });

            //supplier options
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("supplier_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("supp_branch_state_id");
            globalQuery.columns.push("supp_branch_city_id");
            globalQuery.columns.push("supp_branch_gst_no");
            globalQuery.table = "cmv_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "supp_branch_type", operator: "=", value: "Supplier" });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getSupplierList => {
                    //  ** Changes for the Searchable Combo
                    const suppliers = [
                        { value: '', label: 'Select', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        { value: '0', label: 'Add New Record+', supp_branch_state_id: '', supp_branch_city_id: '', supplier_id: '' },
                        ...getSupplierList.map((supplier) => ({ ...supplier, value: supplier.field_id, label: supplier.field_name, supplierGST: supplier.supp_branch_gst_no, supplier_id: supplier.supplier_id })),
                    ];
                    console.log("suppliers details Info", suppliers);
                    setSupplierOptions(suppliers);
                    console.log("Supplier List: ", getSupplierList);
                });

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'property_master_id'];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "property_master_id", operator: "=", value: 180 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'Sales Type' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery).then(getJobTypeAPICall => {
                setJobTypeArr(getJobTypeAPICall);
            })

        } catch (error) {
            console.log("error: ", error)
        }
    }



    const comboOnChange = async (key) => {
        switch (key) {
            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_material_type').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setmaterialType(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_material_type').hide();
                    await generateIndentIssueNo();
                } else {
                    setGoodsReturnNo("0")
                }
                break;

            case 'supplier':
                resetGlobalQuery();
                globalQuery.columns = ["goods_receipt_no", "goods_receipt_date", "purchase_order_no", "purchase_order_date", "supplier_challan_no", "supplier_challan_date", "goods_receipt_master_transaction_id"]
                globalQuery.table = "pt_goods_receipt_master"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
                globalQuery.conditions.push({ field: "supplier_id", operator: "=", value: Number(supplierComboRef.current.value) });
                console.log(`SUPPLIERID${supplierComboRef.current.value}`)

                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                const getGoodsReceipSummarytList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log(getGoodsReceipSummarytList)
                if (getGoodsReceipSummarytList.length !== 0) {
                    setGRNOptions(getGoodsReceipSummarytList)
                } else {
                    setGRNOptions([])
                }
                break;

            case 'grn':
                setIsLoading(true);
                resetGlobalQuery();
                clearFields();
                const grnNo = $("#cmb_grn_no").val()
                globalQuery.columns = [
                    "pgrd.remark",
                    "pgrd.product_material_name",
                    "pgrd.batch_no",
                    "pgrd.product_material_grn_accepted_quantity",
                    "pgrd.product_material_grn_accepted_weight",
                    "pgrd.no_of_boxes",
                    "pgrd.total_box_weight",
                    "pgrd.total_quantity_in_box",
                    "pgrd.weight_per_box_item",
                    "pgrd.material_rate",
                    "smsdtl.product_rm_id",
                    "smsdtl.product_type_id",
                    "smsdtl.goods_receipt_no",
                    "smsdtl.closing_balance_quantity",
                    "smsdtl.closing_balance_weight",
                    "smsdtl.closing_no_of_boxes"
                ]
                globalQuery.table = "ptv_goods_receipt_details pgrd"

                globalQuery.joins = [
                    {
                        "table": `sm_product_rm_stock_details smsdtl`,
                        "type": "LEFT",
                        "on": [
                            { "left": "pgrd.goods_receipt_no", "right": "smsdtl.goods_receipt_no" },
                            { "left": "pgrd.product_material_id", "right": "smsdtl.product_rm_id" },
                            { "left": "smsdtl.day_closed", "right": 0 },
                        ]
                    }
                ]
                globalQuery.conditions.push({ field: "pgrd.goods_receipt_no", operator: "=", value: grnNo });
                globalQuery.conditions.push({ field: "pgrd.is_delete", operator: "=", value: '0', });
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });

                const apiResponse = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log(apiResponse)
                if (apiResponse.length !== 0) {
                    setShowAddUpdateBtn(true)
                    setGrnDetails(apiResponse)
                    // setMaterialRate(apiResponse[0].material_rate)
                } else {
                    setGrnDetails([])
                }
                setIsLoading(false)
                break;

            default:
                break;
        }
    }

    const FnCheckUpdateResponce = async () => {
        debugger
        try {
            setShowAddUpdateBtn(true)
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnShowParticularRecordForUpdate/${goodsReturnMasterId}/${COMPANY_ID}`)
            const response = await apiCall.json();

            let masterData = response.GoodReturnsMasterData
            let detailsData = response.GoodReturnsDetailsRecord
            setGrnDetails(detailsData)
            setmaterialType(masterData.product_type_id)
            setGoodsReturnNo(masterData.goods_return_no)
            setGoodsReturnDate(masterData.goods_return_date)
            setGoodsReturnVersion(masterData.goods_version)
            setSupplier_id(masterData.supplier_id)
            setSalesOrderJob(masterData.sales_type)
            supplierComboRef.current.value = masterData.supplier_id;
            setGRNno(masterData.goods_receipt_no)
            debugger
            if (detailsData && detailsData.length > 0) {
                const newInputValues = detailsData.reduce((acc, item, index) => {
                    acc[index] = {
                        return_quantity: item.goods_return_quantity || 0,
                        return_weight: item.weight_per_box_item * item.goods_return_quantity || 0,
                        return_boxes: item.goods_return_boxes || 0,
                        return_rate: item.goods_return_rate || 0,
                    };
                    return acc;
                }, {})
                setInputValues(newInputValues);
            }
            await comboOnChange("supplier")
            //sales type both
            $('#nxtBtn').hide();


            switch (response.is_active) {
                case true:
                    document.querySelector('input[name="isSupplierActive"][value="1"]').checked = true;
                    break;
                case false:
                    document.querySelector('input[name="isSupplierActive"][value="0"]').checked = true;
                    break;
            }
            setIsLoading(false)

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnValidateSupplierCombo = () => {
        $("#error_cmb_supplier_id").hide();
        $("#error_cmb_grn_no").hide();
    }

    const FnAddUpdateRecord = async () => {
        debugger
        if (cmb_material_type === "" || cmb_sales_job_type === "" || cmb_supplier_id === "" || cmb_grn_no === "") {
            if (cmb_material_type === "") {
                $("#error_cmb_material_type").text("Please fill this field...!");
                $("#error_cmb_material_type").show();
                $("#cmb_material_type").focus();
                return
            }
            else {
                $("#error_cmb_material_type").hide();
            }
            if (cmb_material_type !== "" && cmb_sales_job_type === "") {
                $("#error_cmb_sales_job_type_id").text("Please fill this field...!");
                $("#error_cmb_sales_job_type_id").show();
                $("#cmb_sales_job_type_id").focus();
                return
            }
            else {
                $("#error_cmb_sales_job_type").hide();
            }
            if (cmb_material_type !== "" && cmb_sales_job_type !== "" && cmb_supplier_id === "") {
                $("#error_cmb_supplier_id").text("Please fill this field...!");
                $("#error_cmb_supplier_id").show();
                $("#cmb_supplier_id").focus();
                return
            }
            else {
                $("#error_cmb_supplier_id").hide();
            }
            if (cmb_material_type !== "" && cmb_sales_job_type !== "" && cmb_supplier_id !== "" && cmb_grn_no === "") {
                $("#error_cmb_grn_no").text("Please select Goods Receipt No.");
                $("#error_cmb_grn_no").show();
                $("#cmb_grn_no").focus();
                return
            }
            else {
                $("#error_cmb_grn_no").hide();
            }
        }
        else {
            const json = { 'GoodsReturnMasterData': {}, 'GoodsReturnDetailData': [] }
            const masterData = {
                goods_return_master_id: keyForViewUpdate === "" ? 0 : goodsReturnMasterId,
                product_type_id: cmb_material_type,
                goods_return_no: txt_goods_return_no,
                goods_return_date: dt_goods_return_date,
                goods_receipt_no: cmb_grn_no,
                goods_version: txt_goods_return_version,
                supplier_id: cmb_supplier_id,
                sales_type: cmb_sales_job_type,
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                is_active: true,
                is_delete: false,//
                created_by: UserName,
                modified_by: UserName,
                deleted_by: UserName,
            };
            const detailsDataArray = GrnDetails.map((grnDetail, index) => ({
                product_rm_id: grnDetail.product_rm_id,
                goods_return_quantity: inputValues[index]?.return_quantity || '',
                goods_return_weight: GrnDetails[index]?.weight_per_box_item * inputValues[index]?.return_quantity || '',
                goods_return_boxes: inputValues[index]?.return_boxes || '',
                goods_return_rate: inputValues[index]?.return_rate || '',
                goods_receipt_no: grnDetail.goods_receipt_no,
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                is_active: true,
                is_delete: false,
                created_by: "admin",
                created_on: "2024-11-12T10:00:00",
                modified_by: UserName,
                modified_on: "2024-11-12T10:00:00",
                deleted_by: null,
                deleted_on: null
            }));

            json.GoodsReturnMasterData = masterData;
            json.GoodsReturnDetailData = detailsDataArray;
            const formData = new FormData()
            formData.append('GoodsReturnsDetails', JSON.stringify(json))
            const forwardData = {
                method: 'POST',
                body: formData,
            }
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnAddUpdateRecord`, forwardData)
            const responce = await apiCall.json();
            console.log("response error: ", responce.data);
            if (responce.error !== "") {
                console.log("response error: ", responce.error);
                setErrMsg(responce.error)
                setShowErrorMsgModal(true)

            } else {
                setSuccMsg(responce.message);
                setShowSuccessMsgModal(true);
                // navigate(`/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing`)
            }

        }

    };

    const generateIndentIssueNo = async () => {
        const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_material_type').val()));
        const productTypeId = $('#cmb_material_type option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_material_issue_master", "issue_no", ptShortName.product_type_short_name, "MI", "5", "indent_issue_type_id", productTypeId);
        setGoodsReturnNo(autoNoApiCall);
        return autoNoApiCall;
    };

    const onQuantityChange = (quantity, wt_per_box) => {

        setGoodsReturnQuantity(quantity);
        setGoodsReturnWeight(quantity * wt_per_box || 0);
        //jquery for showing error
    }

    const editUpdateRecord = (index, action) => {
        switch (action) {
            case 'delete':
                setGrnDetails((prevGrnDetails) => {
                    return prevGrnDetails.filter((grnD, i) => i !== index)
                })
                break;
        }
    }

    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'product_material_indent_quantity', 'product_material_indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "issue_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "product_material_reserve_quantity",
        "product_material_reserve_weight", "product_material_receipt_quantity", "product_material_reserve_weight", "product_material_receipt_weight", "product_type_id",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time', 'godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name'
    ];

    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('GoodsReturnEntryFormId')
    }

    // ------------------------ Changes for the Searchable Combos. --------------------------
    let customerComboRef = useRef(null);
    let costCenterComboRef = useRef(null);
    let requesitionByComboRef = useRef(null);
    let indentNosComboRef = useRef(null);
    let supplierComboRef = useRef(null);
    let grnComboRef = useRef(null)

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}
                    {/* <form id="GoodsReturnEntryFormId"> */}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Goods Return / Sale Purchase {action_Label}</label>
                        </div>
                        <form id="GoodsReturnEntryFormId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Goods Return Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_material_type"
                                                className="form-select form-select-sm"
                                                disabled={['view', 'update'].includes(keyForViewUpdate)}
                                                value={cmb_material_type}
                                                onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Goods Return No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_goods_return_no" className="erp_input_field" value={txt_goods_return_no} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_goods_return_version' className="erp_input_field text-end" value={txt_goods_return_version} disabled />
                                                    <MDTypography variant="button" id="txt_goods_return_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_goods_return_date' className="erp_input_field" value={dt_goods_return_date}
                                                        disabled={['view', 'update'].includes(keyForViewUpdate)}
                                                        onChange={e => { setGoodsReturnDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="dt_goods_return_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Sales Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_sales_job_type_id"
                                                className="form-select form-select-sm"
                                                value={cmb_sales_job_type}
                                                onChange={(e) => { setSalesOrderJob(e.target.value); validateFields(); }}
                                                // optional='optional'
                                                disabled={['view', 'update'].includes(keyForViewUpdate)}>
                                                <option value="" >Select</option>
                                                {jobTypeOptions?.map(jobtype => (
                                                    <option value={jobtype.field_name}>{jobtype.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sales_job_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Supplier<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <Select ref={supplierComboRef}
                                                options={supplierOptions}
                                                isDisabled={['view', 'update'].includes(keyForViewUpdate)}
                                                inputId="cmb_supplier_id" // Provide the ID for the input box
                                                value={supplierOptions.find(option => option.value === cmb_supplier_id)}
                                                onChange={(selectedOpt) => {
                                                    setSupplier_id(selectedOpt.value);
                                                    supplierComboRef.current = selectedOpt ?? { value: "" };
                                                    comboOnChange("supplier");
                                                    validateFields();
                                                    FnValidateSupplierCombo();

                                                }}
                                                placeholder="Search for a supplier..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_supplier_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Goods Receipt No. <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <select
                                                ref={grnComboRef}
                                                id="cmb_grn_no"
                                                value={cmb_grn_no}
                                                className="form-select form-select-sm"
                                                onChange={(event) => {
                                                    const selectedValue = event.target.value;
                                                    grnComboRef.current = selectedValue
                                                    setGRNno(selectedValue);
                                                    comboOnChange("grn");
                                                    validateFields();
                                                }}
                                                disabled={['view', 'update'].includes(keyForViewUpdate)}
                                            >
                                                <option value="" disabled>
                                                    Select
                                                </option>
                                                {grnOptions.map((option) => (
                                                    <option key={option.value} value={option.goods_receipt_no}>
                                                        {option.goods_receipt_no}
                                                    </option>
                                                ))}
                                            </select>

                                            <MDTypography variant="button" id="error_cmb_grn_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {
                            // error check?
                            GrnDetails?.length !== 0 ? <>
                                <hr></hr>
                                <Table style={{ border: "1px solid #cbcbcb", margin: "1rem 0rem", width: "100%" }} responsive bordered striped className="erp_table">
                                    <thead className="erp_table_head">
                                        <th className="erp_table_th">Action</th>
                                        <th className="erp_table_th">Sr No.</th>
                                        <th className="erp_table_th">Material name</th>
                                        <th className="erp_table_th">Batch No.</th>
                                        <th className="erp_table_th">Accepted Qty</th>
                                        <th className="erp_table_th">Accepted Wt</th>
                                        <th className="erp_table_th">Total Qty in boxes</th>
                                        <th className="erp_table_th">Wt. per box</th>
                                        <th className="erp_table_th">No. of boxes</th>
                                        <th className="erp_table_th">GRN remark</th>
                                        <th className="erp_table_th">Closing quantity</th>
                                        <th className="erp_table_th">Closing Wt.</th>
                                        <th className="erp_table_th">Closing no of boxes</th>
                                        <th className="erp_table_th">Return Quantity</th>
                                        <th className="erp_table_th">Return Weight</th>
                                        <th className="erp_table_th">Return boxes</th>
                                        <th className="erp_table_th">Material rate</th>
                                    </thead>

                                    <tbody>
                                        {GrnDetails.map((docItem, Index) => (
                                            <tr key={Index} style={{ height: "2rem", verticalAlign: "middle" }}>
                                                <td>
                                                    <MdDelete
                                                        style={{ fontSize: "0.8rem" }}
                                                        className={`erp-delete-btn ${action_Label !== "view" ? 'display' : 'd-none'}`}
                                                        onClick={() => editUpdateRecord(Index, 'delete')}
                                                    />
                                                </td>
                                                <td className="erp_table_td">{Index + 1}</td>
                                                <td className="erp_table_td">{docItem.product_material_name}</td>
                                                <td className="erp_table_td">{docItem.batch_no}</td>
                                                <td className="erp_table_td">{docItem.product_material_grn_accepted_quantity}</td>
                                                <td className="erp_table_td">{docItem.product_material_grn_accepted_weight}</td>
                                                <td className="erp_table_td">{docItem.total_quantity_in_box}</td>
                                                <td className="erp_table_td">{docItem.weight_per_box_item}</td>
                                                <td className="erp_table_td">{docItem.no_of_boxes}</td>
                                                <td className="erp_table_td">{docItem.remark}</td>
                                                <td className="erp_table_td">{docItem.closing_balance_quantity}</td>
                                                <td className="erp_table_td">{docItem.closing_balance_weight}</td>
                                                <td className="erp_table_td">{docItem.closing_no_of_boxes}</td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        className="erp_input_field"
                                                        id={`return_quantity_${Index}`}
                                                        disabled={keyForViewUpdate === "view"}
                                                        value={inputValues[Index]?.return_quantity || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_quantity', e.target.value)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        id={`error_return_quantity_${Index}`}
                                                        className="erp_validation"
                                                        fontWeight="regular"
                                                        color="error"
                                                        style={{ display: "none", fontSize: "10px" }}
                                                    >
                                                        {/* Error message will be set dynamically */}
                                                    </MDTypography>
                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_weight_${Index}`}
                                                        className="erp_input_field"
                                                        disabled
                                                        value={inputValues[Index]?.return_weight || ''}
                                                        style={{ marginBottom: "0px" }}
                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        id={`error_return_weight_${Index}`}
                                                        className="erp_validation"
                                                        fontWeight="regular"
                                                        color="error"
                                                        style={{ display: "none" }}
                                                    >
                                                        {/* Error message will be set dynamically */}
                                                    </MDTypography>
                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_boxes_${Index}`}
                                                        className="erp_input_field"
                                                        disabled={keyForViewUpdate === "view"}
                                                        value={inputValues[Index]?.return_boxes || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_boxes', e.target.value)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        id={`error_return_boxes_${Index}`}
                                                        className="erp_validation"
                                                        fontWeight="regular"
                                                        color="error"
                                                        style={{ display: "none" }}
                                                    >
                                                        {/* Error message will be set dynamically */}
                                                    </MDTypography>
                                                </td>
                                                <td className="erp_table_td">
                                                    <Form.Control
                                                        type="text"
                                                        id={`return_rate_${Index}`}
                                                        className="erp_input_field"
                                                        disabled={keyForViewUpdate === "view"}
                                                        value={inputValues[Index]?.return_rate || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(Index, 'return_rate', e.target.value)
                                                        }
                                                        style={{ marginBottom: "0px" }}
                                                    />
                                                    <MDTypography
                                                        variant="button"
                                                        id={`error_return_rate_${Index}`}
                                                        className="erp_validation"
                                                        fontWeight="regular"
                                                        color="error"
                                                        style={{ display: "none" }}
                                                    >
                                                        {/* Error message will be set dynamically */}
                                                    </MDTypography>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                    {/* <tbody>
                                        <td className="erp_table_td">1</td>
                                        <td className="erp_table_td">{GrnDetails?.product_material_name}</td>
                                        <td className="erp_table_td">{GrnDetails?.batch_no}</td>
                                        <td className="erp_table_td">{GrnDetails?.product_material_grn_accepted_quantity}</td>
                                        <td className="erp_table_td">{GrnDetails?.product_material_grn_accepted_weight}</td>
                                        <td className="erp_table_td">{GrnDetails?.total_quantity_in_box}</td>
                                        <td className="erp_table_td">{GrnDetails?.weight_per_box_item}</td>
                                        <td className="erp_table_td">{GrnDetails?.no_of_boxes}</td>
                                        <td className="erp_table_td">{GrnDetails?.remark}</td>
                                        <td className="erp_table_td">{GrnDetails?.closing_balance_quantity}</td>
                                        <td className="erp_table_td">{GrnDetails?.closing_balance_weight}</td>
                                        <td className="erp_table_td">{GrnDetails?.closing_no_of_boxes}</td>
                                        <td className="erp_table_td">
                                            <input
                                                type="text"
                                                id="return_quantity"
                                                value={txt_goods_return_quantity}
                                                onChange={(e) => { onQuantityChange(e.target.value) }}
                                            />
                                        </td>
                                        <td className="erp_table_td">
                                            <input
                                                type="text"
                                                id="return_weight"
                                                disabled
                                                value={txt_goods_return_weight}
                                            // onChange={(e) => {setGoodsReturnWeight(e.target.value)}}
                                            />
                                        </td>
                                        <td className="erp_table_td">
                                            <input
                                                type="text"
                                                id="return_boxes"
                                                value={txt_goods_return_boxes}
                                                onChange={(e) => { setGoodsReturnBoxes(e.target.value) }}
                                            />
                                        </td>
                                        <td className="erp_table_td">
                                            <input
                                                type="text"
                                                id="return_boxes"
                                                value={txt_material_rate}
                                                onChange={(e) => { setMaterialRate(e.target.value) }}
                                            />
                                        </td>
                                    </tbody> */}

                                </Table></> : <></>}
                    </div>

                    <div className='erp_frm_Btns'>
                        <MDButton type="button"
                            onClick={() => {
                                const path = compType === 'Purchase' ? '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnListing' : '/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry';
                                navigate(path);
                            }}
                            className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" >Back</MDButton> &nbsp;
                        {showAddUpdateBtn && <MDButton type="button" onClick={FnAddUpdateRecord} className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" >{keyForViewUpdate === "" ? "Save" : "Update"}</MDButton>}
                    </div>

                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                    <hr />
                    {/* </form> */}
                </>
            </DashboardLayout >
        </>
    )
}