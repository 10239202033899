import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import $ from 'jquery';
import ReactPaginate from "react-paginate";

// React icons
import { AiOutlineDownCircle } from "react-icons/ai";
import { TbArrowsRight } from "react-icons/tb";
import { TbArrowsLeft } from "react-icons/tb";
import { TbArrowsDown } from "react-icons/tb";
import { TbArrowsUp } from "react-icons/tb";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";


// React Bootstrap imports
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';


//Export Related imports
import PdfExport from 'Features/Exports/PdfExport';
import ExcelExport from "Features/Exports/ExcelExport";
import JsonExport from "Features/Exports/JsonExport";
import CSVExport from "Features/Exports/CSVExport";
import { FiDownload } from 'react-icons/fi';
import Datatable from 'components/DataTable';
import ComboBox from 'Features/ComboBox';
import ConfigConstants from 'assets/Constants/config-constant';
import { resetGlobalQuery } from 'assets/Constants/config-constant';
import { globalQuery } from 'assets/Constants/config-constant';
import DeleteModal from 'components/Modals/DeleteModal';
import { CircularProgress } from '@material-ui/core';
import { FaTimes } from "react-icons/fa";
import { RiSearchLine } from 'react-icons/ri';

export default function FrmGoodsReturnListing({ compType = 'Purchase' }) {
  var storeSelectedValues = [];
  const comboBox = useRef();
  const configConstants = ConfigConstants();
  const { COMPANY_ID, COMPANY_NAME, UserName, USER_ACCESS } = configConstants;

  // var companyId = 1;
  // var companyBranchID = 1;
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [goods_return_master_id, setGoodsReturnMasterId] = useState();
  const [reportType, setReportType] = useState('summary');
  const [availableColumns, setAvailableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchState, setGlobalFilterSearch] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')
  const [cmb_supplier_type, setSupplierType] = useState('');


  // Table Data
  const [data, setGoodsReturnData] = useState([]);
  const [columns, setColumns] = useState([]);

  // Pagination Variables 
  const pageEntriesOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "All", value: 0 },
  ]
  const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
  const [pageCount, setpageCount] = useState(0);
  const [PageCurrent, setcurrentPage] = useState(0);


  // Export Fields
  const pdfExp = useRef();
  const exlsExp = useRef();
  const jsonExp = useRef();
  const pdfExpFilters = {};
  const csvExp = useRef();
  var reportName = "Supplier Report";

  var dataExport = [];
  var columnExport = [];
  // Filter Fields
  var rptColumnHeads;
  const [filterComboBox, setFilterComboBox] = useState([]);
  const [recordData, setRecordData] = useState([]);

  // Popup Fields
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  useEffect(async () => {
    $('.hide-show-filters').hide();
    const availCols = await showReportRecords(reportType);
    await fetchFilteredData(PageCurrent, entriesPerPage, availCols)
  }, [compType])

  const showReportRecords = async (reportIdentifierKey) => {
    try {
      // const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${'summary'}&viewName=${'ptv_goods_return_details_rpt'}`)
      const summaryRptApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/report/FnShowAllReportRecords?reportType=${reportIdentifierKey}&viewName=${reportIdentifierKey === 'summary' ? 'ptv_goods_return_master_rpt' : 'ptv_goods_return_details_rpt'}`)
      const summaryResponce = await summaryRptApiCall.json();
      console.log("summaryResponce responce: ", summaryResponce)
      if (summaryResponce.content.length !== 0) {
        var rptColumnHeads = summaryResponce.headerKeys;
        setRecordData(summaryResponce.content)
        let filterBox = [];
        let tempOptionBox = []
        for (let colKey = 0; colKey < rptColumnHeads.length; colKey++) {
          const content = summaryResponce.content;
          const columnName = rptColumnHeads[colKey];
          const value = content[columnName];
          if (value !== null) {
            const myArray = value.split(":");

            switch (myArray[2]) {
              case 'Y':
                filterBox.push({ Headers: myArray[1], accessor: columnName });
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              case 'O':
                tempOptionBox.push({ Headers: myArray[1], accessor: columnName });
                break;
              default:
                break

            }
          }
        }
        setFilterComboBox(filterBox)
        setAvailableColumns(tempOptionBox)
        setGroupByColumns(tempOptionBox)
        return tempOptionBox;
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }

  }

  const handlePageClick = async (pageNo) => {
    let currentPage = pageNo.selected;
    setcurrentPage(currentPage);
    const commentsFormServer = await fetchFilteredData(currentPage, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    console.log("commentsFormServer: ", commentsFormServer)

  }
  const handlePageCountClick = async () => {
    let count = document.getElementById("page_entries_id").value;
    setEntriesPerPage(count)
    setcurrentPage(0)
    await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  }


  var operatorLabels = { "=": "is", "!=": "is not", "1": "active", "0": "closed", "o": "open", "!*": "none", "*": "any", "~": "contains", "!~": "doesn't contain", "^": "starts with", "$": "ends with", "<=": "less than or equal", ">=": "greater than or equal", '<>=': 'BETWEEN' };
  var operatorByType = {
      "list": ["=", "!="], "status": ["1", "0"], "list_status": ["o", "=", "!", "c", "*"], "list_optional": ["=", "!", "!*", "*"],
      "list_subprojects": ["*", "!*", "=", "!"], "string": ["~", "=", "!~", "!=", "^", "$"], "text": ["~", "!~", "^", "$", "!*", "*"],
      "integer": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"], "float": ["=", "\u003e=", "\u003c=", "\u003e\u003c", "!*", "*"],
      "relation": ["=", "!", "=p", "=!p", "!p", "*o", "!o", "!*", "*"], "tree": ["=", "~", "!*", "*"], "date": ["=", "<=", '>=', '<>='],
  };

  const reload = () => {
    window.location.reload();
  }


  const openForm = () => {
    navigate("/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry", { state: { goodsReturnMasterId: 0, keyForViewUpdate: '', compType: compType } })
  }

  const infoForUpdate = ({ goods_return_master_id, supplier_code, supplier_name }, key) => {
    navigate("/Transactions/TPurchaseOrder/GoodsReturn/FrmGoodsReturnEntry", { state: { goodsReturnMasterId: goods_return_master_id, supplierCode: supplier_code, supplierName: supplier_name, keyForViewUpdate: key, compType: compType } })
  }

  function viewUpdateDelete(data, key) {
    var goods_return_master_id = data.goods_return_master_id
    setGoodsReturnMasterId(goods_return_master_id)
    switch (key) {
      case 'update': infoForUpdate(data, 'update'); break;
      case 'delete': setShow(true); break;
      case 'view': infoForUpdate(data, 'view'); break;
    }
  }

  async function DeleteSupplier() {
    try {
      const method = { method: 'DELETE' }
      const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/GoodsReturnsDetails/FnDeleteRecord/${goods_return_master_id}/${COMPANY_ID}/${UserName}`, method)
      const responce = await deleteApiCall.json();
      console.log("Goods Return Entry Deleted: ", responce);
      setShow(false)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    }
  }
  const FnCheckUserAccess = () => {
    const currentRoute = window.location.pathname;
    const obj = USER_ACCESS ? USER_ACCESS.find(item => item.listing_navigation_url === currentRoute) : null;
    console.log("Particular Page user access: ", obj);
    return obj;
  }

  const globalSearchOnChange = async () => {
    debugger
    if (entriesPerPage !== 0) {
      setcurrentPage(0)
      setEntriesPerPage(0)
      await fetchFilteredData(0, 0, selectedColumns.length !== 0 ? selectedColumns : availableColumns)
    }
    setSearchInputValue(searchState)
  }

  const FnRenderAdditionalInputsOnDateChange = (filter) => {
    if (filter.type === 'D' && document.getElementById(`operators_${filter.id}_id`).value === '<>=') {
      const filterTblRow = document.getElementById(`${filter.id}`);
      const filterTblRowTd = document.createElement('td');

      // Create the first input element
      const dateInput = document.createElement('input');
      dateInput.type = 'date';
      dateInput.id = `values_${filter.id}_id_2`;
      dateInput.className = 'erp_form_control erp_input_field inputValue erp_operator_val ms-4';

      filterTblRowTd.appendChild(dateInput);
      filterTblRow.appendChild(filterTblRowTd);
    } else {
      // Remove the existing td if it exists
      const existingTd = document.getElementById(`values_${filter.id}_id_2`);
      if (existingTd) {
        existingTd.parentNode.removeChild(existingTd);
      }
    }
    return null;
  };

  // <!------------Filter Start------------!>

  const addSelectedColumns = () => {
    const selectedOption = document.getElementById("availableColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = availableColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns(availableColumns.filter(column => column.accessor !== selectedValue));
        setSelectedColumns([...selectedColumns, selectedColumn]);
      }
    }
  }

  const moveAllToSelectedColumns = () => {
    setSelectedColumns([...selectedColumns, ...availableColumns]);
    setAvailableColumns([]);
  }

  const addAvailableColumns = () => {
    const selectedOption = document.getElementById("selectedColumns");
    const selectedValue = selectedOption.value;
    if (selectedValue) {
      const selectedColumn = selectedColumns.find(column => column.accessor === selectedValue);
      if (selectedColumn) {
        setAvailableColumns([...availableColumns, selectedColumn]);
        setSelectedColumns(selectedColumns.filter(column => column.accessor !== selectedValue));
      }
    }
  }

  const moveAllToAvailableColumns = () => {
    // Move all selected columns to available columns
    setAvailableColumns([...availableColumns, ...selectedColumns]);

    // Clear the selected columns
    setSelectedColumns([]);
  };

  const moveOptionUp = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex > 0) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex - 1]] = [newSelectedColumns[selectedIndex - 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };

  const moveOptionDown = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);

    if (selectedIndex < selectedColumns.length - 1 && selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      [newSelectedColumns[selectedIndex], newSelectedColumns[selectedIndex + 1]] = [newSelectedColumns[selectedIndex + 1], newSelectedColumns[selectedIndex]];
      setSelectedColumns(newSelectedColumns);
    }
  };
  const moveOptionTop = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);
    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([movedColumn, ...newSelectedColumns]);
    }
  };

  const moveOptionBottom = () => {
    const selectedIndex = selectedColumns.findIndex(column => column.accessor === selectedValue);
    if (selectedIndex !== -1) {
      const newSelectedColumns = [...selectedColumns];
      const movedColumn = newSelectedColumns.splice(selectedIndex, 1)[0];
      setSelectedColumns([...newSelectedColumns, movedColumn]);
    }
  };


  function toggleFilter() {
    $(this).text(function (_, currentText) {
      return currentText == "▼" ? "▲" : "▼";
    });
    $('.hide-show-filters').toggle('fast');
  };

  const addFilterSelect = async () => {
    let masterList;
    let filterText = document.getElementById('add_filter_select');
    if (filterText.value !== '0') {
      document.querySelectorAll("#add_filter_select option").forEach(opt => {
        if (opt.value === filterText.value) {
          opt.disabled = true;
        }
      });
      const value = recordData[filterText.value];
      const myArray = value.split(":");
      const newFilter = {
        id: filterText.value,
        label: myArray[1],
        type: myArray[3],
        dataKey: myArray[5],
        operatorArray: [],
        dataArray: []
      };
      switch (myArray[3]) {
        case 'C':
          if (myArray[5] === 'O') {
            resetGlobalQuery();
            globalQuery.columns.push(`DISTINCT (${filterText.value})`);
            // globalQuery.conditions.push({
            //   field: "company_id",
            //   operator: "=",
            //   value: COMPANY_ID
            // });
            globalQuery.conditions.push({
              field: "is_delete",
              operator: "=",
              value: 0
            });
            globalQuery.table = myArray[4]
            masterList = await comboBox.current.fillFiltersCombo(globalQuery)
          } else {
            masterList = await comboBox.current.fillMasterData(myArray[4], "", "")
          }

          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = masterList
          break;
        case 'P':
          let propertyList = await comboBox.current.fillComboBox(myArray[4])
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = propertyList
          break;
        case 'T':
          newFilter.operatorArray = operatorByType.string
          break;
        case 'H':
          // Extracting values within parentheses from the last element
          const valuesInParentheses = myArray[myArray.length - 1].slice(1, -1);
          // Splitting the string into an array of values
          const resultArray = valuesInParentheses.split(',');
          console.log(resultArray);
          newFilter.operatorArray = operatorByType.list
          newFilter.dataArray = resultArray
          break;
        case 'D':
          newFilter.operatorArray = operatorByType.date
          break;
      }
      // Update the state with the new filter
      setSelectedFilters(prevFilters => [...prevFilters, newFilter]);
    }
  }

  const removeFilter = (filterId) => {
    setSelectedFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
    document.querySelectorAll("#add_filter_select option").forEach(opt => {
      if (opt.value === filterId) {
        opt.disabled = false;
      }
    });
    $("#add_filter_select").val('0');
  };


  const submitQuery = async (availCols) => {
    try {
      const requiredColumns = ['goods_return_master_id']


      let selectBox = document.getElementById("selectedColumns");
      let group_by = document.getElementById("group_by").value;
      let jsonQuery = { 'viewname': {}, 'selectQuery': {}, 'whereQuery': {}, 'operators': {}, 'group_by': {}, "additionalParam": {}, "orderBy": {} };
      storeSelectedValues = []
      if (selectBox.length !== 0) {
        let selectOrder = []; // Array to store the order of elements
        let storeSelectedValues = []; // Assuming this is defined somewhere in your code

        // Loop through selectBox to populate selectQuery and selectOrder
        for (let index = 0; index < selectBox.length; index++) {
          let optionValue = selectBox.options[index].value;
          jsonQuery['selectQuery'][index] = optionValue;
          selectOrder.push(index); // Store the index in the order array
          storeSelectedValues.push(optionValue);
        }

        // Loop through requiredColumns to add missing elements in the specified order
        for (let index = 0; index < requiredColumns.length; index++) {
          const element = requiredColumns[index];
          if (!jsonQuery.selectQuery.hasOwnProperty(element)) {
            // Add the element at the end of the order array and assign the value
            selectOrder.push(selectOrder.length);
            jsonQuery['selectQuery'][selectOrder.length - 1] = element;
          }
        }

        // Now, construct the final selectQuery object using the specified order
        let finalSelectQuery = {};
        for (let orderIndex of selectOrder) {
          finalSelectQuery[orderIndex] = jsonQuery['selectQuery'][orderIndex];
        }

        // Replace the original selectQuery with the finalSelectQuery
        jsonQuery['selectQuery'] = finalSelectQuery;


      } else {
        for (let index = 0; index < availCols.length; index++) {
          let optionValue = availCols[index].accessor;
          jsonQuery['selectQuery'][optionValue] = optionValue;
        }
      }

      for (let selIndex = 0; selIndex < selectedFilters.length; selIndex++) {
        let fieldvalue = selectedFilters[selIndex].id.trim()
        let operatorSelectValue = document.getElementById(`operators_${fieldvalue}_id`).value;
        let valueSelectValue = document.getElementById(`values_${fieldvalue}_id`).value;
        if (selectedFilters[selIndex].type === 'T') {
          switch (operatorSelectValue) {
            case '~':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '!~':
              operatorSelectValue = "NOT LIKE"
              valueSelectValue = "%" + valueSelectValue + "%";
              break;
            case '^':
              operatorSelectValue = "LIKE"
              valueSelectValue = "%" + valueSelectValue;
              break;
            case '$':
              operatorSelectValue = "LIKE"
              valueSelectValue = valueSelectValue + "%";
              break;
            default:
              break;
          }
        }

        if (selectedFilters[selIndex].type === 'D' && operatorSelectValue === '<>=') {
          if (document.getElementById(`values_${fieldvalue}_id_2`).value !== '' && document.getElementById(`values_${fieldvalue}_id`).value !== '') {
            valueSelectValue = `${document.getElementById(`values_${fieldvalue}_id_2`).value}`
            operatorSelectValue = `BETWEEN '${document.getElementById(`values_${fieldvalue}_id`).value}' AND `
            // this for display filters on reports
            pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue + ` BETWEEN ` + `${document.getElementById(`values_${fieldvalue}_id`).value}`;
          } else {
            continue;
          }

        } else {
          // this for display filters on reports
          pdfExpFilters[selectedFilters[selIndex].label] = valueSelectValue;
        }
        jsonQuery['whereQuery'][fieldvalue] = valueSelectValue;
        jsonQuery['operators'][fieldvalue] = operatorSelectValue;
      }
      jsonQuery['group_by']["GROUP BY"] = group_by;
      jsonQuery['additionalParam']['is_delete'] = "0";
      // jsonQuery['additionalParam']['company_id'] = COMPANY_ID;
      var supplierTypeVal = document.getElementById("cmb_supplier_type").value;
      if (supplierTypeVal !== "") {
        jsonQuery['additionalParam']['supplier_type'] = supplierTypeVal;
      }
      jsonQuery['orderBy']['ORDER BY'] = 'goods_return_master_id';
      if (reportType === 'summary') {
        jsonQuery['viewname']['viewName'] = 'ptv_goods_return_master'
    } else {
        jsonQuery['viewname']['viewName'] = 'ptv_goods_return_detail'
    }

      let executeQuery = JSON.stringify(jsonQuery)
      return executeQuery;

    } catch (error) {
      console.log("error", error);
      navigate('/Error')
    }
  }
  const fetchFilteredData = async (page, size, availCols) => {
    try {
      setIsLoading(true)

      const executeQuery = await submitQuery(availCols);

      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };

      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnMShowFilterRecords/${page}/${size}`, requestOptions)
      const responce = await fetchResponse.json();
      let nColumn = [];
      if (responce.content.length > 0) {
        // This for pagination 
        const access_control = FnCheckUserAccess() || { all_access: false, read_access: false, modify_access: false, delete_access: false }; // Setting default access control when USER_ACCESS is undefined
        const total = responce.totalElements;
        setpageCount(parseInt(size) !== 0 ? Math.ceil(total / size) : 1);

        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers
          if (colKey === 0) {
            nColumn.push({
              Headers: "Action", accessor: "Action",
              Cell: row => (
                <div style={{ display: "flex" }}>
                  <AiFillEye className={`erp-view-btn ${access_control.all_access || access_control.read_access ? 'display' : 'd-none'}`} onClick={() => viewUpdateDelete(row.original, 'view')} />
                  {compType === 'Register' ?
                    null : <>
                      <MdModeEdit className={`erp-edit-btn ${access_control.all_access || access_control.modify_access ? 'display' : 'd-none'}`} onClick={e => viewUpdateDelete(row.original, 'update')} />
                      <MdDelete className={`erp-delete-btn ${access_control.all_access || access_control.delete_access ? 'display' : 'd-none'}`} onClick={() => viewUpdateDelete(row.original, 'delete')} />
                    </>
                  }
                </div>
              ),
            });
          }
          nColumn.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }
        setColumns(nColumn);
        setGoodsReturnData(responce.content)
      } else {
        setColumns([]);
        setGoodsReturnData([])
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };


  const fetchFilteredDataToExport = async (availCols) => {
    try {
      setIsLoading(true)
      const executeQuery = await submitQuery(availCols);
      const formData = new FormData();
      formData.append(`jsonQuery`, executeQuery)
      const requestOptions = { method: 'POST', body: formData };
      const fetchResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/filter/FnShowFilterRecords`, requestOptions)
      const responce = await fetchResponse.json();

      let filterColumnsToExport = [];

      if (responce.content.length > 0) {
        // var newColumnHeads = responce.Headerkeys
        // Get column Name & Accessor
        let colNameAccessor;
        let colNameHeader;

        for (let colKey = 0; colKey < availCols.length; colKey++) {
          colNameAccessor = availCols[colKey].accessor
          colNameHeader = availCols[colKey].Headers

          filterColumnsToExport.push({ Headers: colNameHeader, accessor: colNameAccessor });
        }

        dataExport = responce.content
        columnExport = filterColumnsToExport
      } else {
        dataExport = []
        columnExport = []
      }
      return responce;
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  };



  // <!------------Filter end------------!>
  const exporttoPdf = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      if (filtrdata.length !== 0) {
        var filtrObjKeys = Object.keys(pdfExpFilters);
        for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
          var key = filtrObjKeys[objKey];
          var value = pdfExpFilters[key];
        }
      }
      if (dataExport.length !== 0) {
        pdfExp.current.pdf(dataExport, columnExport, reportName, pdfExpFilters)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }

  }

  // const exporttoExcel = async () => {
  //   try {
  //     setIsLoading(true)
  //     await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  //     const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
  //     if (dataExport.length !== 0) {
  //       var jsonToExportExcel = { 'allData': {}, 'columns': columnExport, 'filtrKeyValue': {}, 'headings': {}, 'key': 'reportExport' }

  //       if (filtrdata.length !== 0) {
  //         var filtrObjKeys = Object.keys(pdfExpFilters);
  //         for (let objKey = 0; objKey < filtrObjKeys.length; objKey++) {
  //           var key = filtrObjKeys[objKey];
  //           var value = pdfExpFilters[filtrObjKeys[objKey]];
  //           jsonToExportExcel['filtrKeyValue'][objKey] = key + ' : ' + value
  //         }
  //       }

  //       for (let arrKey = 0; arrKey < dataExport.length; arrKey++) {
  //         jsonToExportExcel['allData'][arrKey] = dataExport[arrKey];
  //       }
  //       jsonToExportExcel['headings']['ReportName'] = reportName
  //       jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
  //       jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')

  //       console.log("jsonToExportExcel: ", jsonToExportExcel)
  //       exlsExp.current.excel(jsonToExportExcel, reportName)
  //     }
  //   } catch (error) {
  //     console.log("error: ", error)
  //     navigate('/Error')
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  const exporttoExcel = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      const filtrdata = await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);

      // Filter out columns with 'Action' accessor
      const filteredColumns = columns.filter(item => item.accessor !== 'Action');
      // Function to format date columns
      const formatDateColumns = (data) => {
        return data.map(item => {
          const formattedItem = { ...item };
          filteredColumns.forEach(column => {
            if (column.Headers.includes(' Date') && formattedItem[column.accessor]) {
              formattedItem[column.accessor] = new Date(formattedItem[column.accessor]).toLocaleDateString('en-GB'); // Format as desired
            }
          });
          return formattedItem;
        });
      };

      // Format date columns in the data to be exported
      const formattedData = formatDateColumns(filtrdata.content);

      if (dataExport.length !== 0) {
        exlsExp.current.exportToExcel(formattedData, filteredColumns, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const exporttoJSON = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        jsonExp.current.json(data, reportName)
      }
    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }


  const exportToCSV = async () => {
    try {
      setIsLoading(true)
      await fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      await fetchFilteredDataToExport(selectedColumns.length !== 0 ? selectedColumns : availableColumns);
      if (dataExport.length !== 0) {
        let data = dataExport.map(element => {
          return columnExport.reduce((dataJson, col) => {
            dataJson[col.accessor] = element[col.accessor];
            return dataJson;
          }, {});
        });
        csvExp.current.csv(data, columnExport, reportName)
      }

    } catch (error) {
      console.log("error: ", error)
      navigate('/Error')
    } finally {
      setIsLoading(false)
    }
  }

  const switchReport = () => {
    setSelectedColumns([])
    setSelectedFilters([])
    setFilterComboBox([])
    let activeReport = document.querySelector('input[name=summaryOrDetails]:checked').value
    setReportType(activeReport)
}

  return (
    <>
      <ComboBox ref={comboBox} />
      <PdfExport ref={pdfExp} />
      <ExcelExport ref={exlsExp} />
      <JsonExport ref={jsonExp} />
      <CSVExport ref={csvExp} />
      {isLoading ?
        <div className="spinner-overlay"  >
          <div className="spinner-container">
            <CircularProgress color="primary" />
            <span id="spinner_text" className="text-dark">Loading...</span>
          </div>
        </div> :
        null}

      <DashboardLayout>
        <div className='main_heding'>
          <label className='erp-form-label-lg'>Goods Return / Sale Purchase</label>
        </div>
        <button className="erp_toggle-filter" onClick={toggleFilter}>
          <AiOutlineDownCircle className="rotate_filtr_arrow" />
          <MDTypography component="label" variant="button" className="erp-form-label-md-lg">
            &nbsp; filters
          </MDTypography></button>

        <div className="hide-show-filters card filter">
          <div className="row">

            <div className="col-sm-6 erp_filter_table_avl_col" >
              <div className='container shadow h-100 rounded erp_group-resl_container'>
              <div className='row erp_filter_row_tab_view'>
                                    <div className="erp_form_radio">
                                        <div className="fCheck">
                                            <Form.Check
                                                className="erp_radio_button erp-form-label-md"
                                                label="Summary"
                                                type="radio"
                                                value="summary"
                                                name="summaryOrDetails"
                                                onChange={() => switchReport()}
                                                defaultChecked
                                            />
                                        </div>
                                        <div className="sCheck">
                                            <Form.Check
                                                className="erp_radio_button erp-form-label-md"
                                                label="Details"
                                                value="details"
                                                type="radio"
                                                onChange={() => switchReport()}
                                                name="summaryOrDetails"
                                            />
                                        </div>
                                    </div>
                                </div>
                <div className='row erp_filter_row_tab_view'>
                  <div className='col-sm-4'>
                    <span>
                      <MDTypography component="label" variant="button" className="erp-form-label-md">
                        Available Columns
                      </MDTypography>
                      <select size="10" id="availableColumns" className="erp_form-select-sm-filter erp_form-select-filter">
                        {availableColumns?.map(column => (
                          <option value={column.accessor}>  {column.Headers} </option>
                        ))}
                      </select>

                    </span>
                  </div>

                  <div className='col-sm-1'>
                    <div className="buttons" id="buttons_row1">
                      <TbArrowsRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToSelectedColumns} /><br></br>
                      <HiOutlineArrowNarrowRight size={15} className='buttons_move erp_filtr_moveBtn' onClick={addSelectedColumns} /><br></br>
                      <HiOutlineArrowNarrowLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={addAvailableColumns} /><br></br>
                      <TbArrowsLeft size={15} className='buttons_move erp_filtr_moveBtn' onClick={moveAllToAvailableColumns} />
                    </div>
                  </div>

                  <div className='col-sm-4'>
                    <div className="col">
                      <MDTypography component="label" variant="button" className="erp-form-label-md">
                        Selected Columns
                      </MDTypography>

                      <select size="10" id="selectedColumns" className="erp_form-select-sm-filter erp_form-select-filter" onChange={e => setSelectedValue(e.target.value)}>
                        {selectedColumns.map((column, index) => (
                          <option key={index} value={column.accessor}>{column.Headers}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-1'>
                    <div className="buttons" id="buttons_row2">
                      <TbArrowsUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionTop} /><br></br>
                      <HiOutlineArrowNarrowUp size={15} className="erp_filtr_moveBtn" onClick={moveOptionUp} /><br></br>
                      <HiOutlineArrowNarrowDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionDown} /><br></br>
                      <TbArrowsDown size={15} className="erp_filtr_moveBtn" onClick={moveOptionBottom} />
                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div className="col-sm-6 erp_filter_group-by-result">
              <div className='container shadow h-100 rounded erp_group-resl_container'>

                <div className='row erp_group-reslut-by'>
                  <div className='col-sm-3 filtr_gropBy'>
                    <MDTypography
                      className="erp-form-label-md"
                      component="label"
                      variant="button"
                    >Group results by</MDTypography>
                  </div>
                  <div className='col filtr_gropBy'>
                    <Form.Select size="sm" className="erp_form_control operatorSelect" id="group_by">
                      <option value=""></option>
                      {groupByColumns?.map(column => (
                        <option value={column.accessor}> {column.Headers} </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                <div className="row add-filter add_filter_div">
                  <div className='col-sm-3'>
                    <MDTypography component="label" variant="button" className="erp-form-label-md">
                      Add Filter
                    </MDTypography>
                  </div>
                  <div className="col">
                    <Form.Select size="sm" onChange={addFilterSelect} className="erp_form_control group_by operatorSelect erp_add_filter" id="add_filter_select" >
                      <option value="0"></option>
                      {filterComboBox?.map(column => (
                        <option value={column.accessor}>{column.Headers}</option>

                      ))}
                    </Form.Select>
                  </div>
                </div>


                <table id="filters-table" className='erp-filters-table-scroll'>
                  <tbody>
                    {selectedFilters.map(filter => (
                      <tr id={filter.id} key={filter.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={`cb_${filter.id}_id`}
                            value={filter.id}
                            checked
                            onClick={() => removeFilter(filter.id)}
                          />
                          <label className='erp-form-label'>&nbsp;{filter.label}</label>
                        </td>
                        {/* Operators */}
                        <td>
                          {(filter.type === 'C' || filter.type === 'P' || filter.type === 'H') && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}
                          {filter.type === 'T' && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}

                          {filter.type === 'D' && (
                            <select id={`operators_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control" onChange={() => FnRenderAdditionalInputsOnDateChange(filter)}>
                              {filter.operatorArray.map(operator => (
                                <option key={operator} value={operator}>
                                  {operatorLabels[operator]}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        {/* Values */}
                        <td>
                          {filter.type === 'C' && (
                            <select
                              id={`values_${filter.id}_id`}
                              className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control"
                            >
                              {filter.dataArray.map((item, index) => (
                                <option
                                  key={index}
                                  value={filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                >
                                  {filter.dataKey === 'O' ? item[filter.id] : item.field_name}
                                </option>
                              ))}
                            </select>
                          )}

                          {filter.type === 'P' && (
                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.dataArray.map((item, index) => (
                                <option key={index} value={item.field_name}>
                                  {item.field_name}
                                </option>
                              ))}
                            </select>
                          )}
                          {filter.type === 'T' && (
                            <input type="text" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />

                          )}
                          {filter.type === 'D' && (<>
                            <input type="date" id={`values_${filter.id}_id`} className='erp_form_control erp_input_field inputValue erp_operator_val' />
                          </>
                          )}
                          {filter.type === 'H' && (
                            <select id={`values_${filter.id}_id`} className="form-select form-select-sm operatorSelect erp_operator_val erp_form_control">
                              {filter.dataArray.map((operator, index) => (
                                <option key={index} value={operator}>
                                  {operator}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div className="row btn_row_class">
          <div className="col-sm-8" >
            <MDButton className={`btn erp-gb-button ${compType === 'Register' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" onClick={openForm}>Add</MDButton> &nbsp;
            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={() => fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns)}>Apply Filter</MDButton> &nbsp;
            <MDButton class="btn erp-gb-button" variant="button" fontWeight="regular" onClick={reload}>Clear Filter</MDButton>&nbsp;

            <span className="page_entries">
              <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>

              <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                {pageEntriesOptions.map(pageEntriesOptions => (
                  <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                ))}
              </Form.Select>
              <MDTypography component="label" className="erp-form-label-md ps-2" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Supplier Type</MDTypography>
              <Form.Select className="erp_page_select erp_form_control" style={{ width: '110px' }} id="cmb_supplier_type" value={cmb_supplier_type}
                onChange={async (e) => {
                  setSupplierType(e.target.value);
                  fetchFilteredData(PageCurrent, entriesPerPage, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
                }}>
                <option value='' style={{ color: 'black' }}>Select All</option>
                <option value="Trading">Trading</option>
                <option value="Services">Services</option>
                <option value="Manufacturing(Projects)">Manufacturing(Projects)</option>
                <option value="E-Commerce">E-Commerce</option>
                <option value="Job Works">Job Works</option>
                
              </Form.Select>
            </span>
          </div>
          <div className="col-4 pagination_id">

            <span className="exports">
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoPdf()}>PDF<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoExcel()}>EXCEL<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exportToCSV()}>CSV<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => exporttoJSON()}>JSON<FiDownload className="erp-download-icon-btn" /></MDButton> &nbsp;

            </span>
          </div>
        </div>

        {data.length !== 0 ? <>
          {/* <div className="row mt-1">
            <div className="col-sm-3">
              <div class="input-group">
                <input type="text" className='erp_input_field form-control' style={{ height: '30px' }} value={searchState || ''}
                  onChange={(e) => setGlobalFilterSearch(e.target.value)} placeholder="Search!..." aria-describedby="basic-addon2" />
                <span class="input-group-text" id="basic-addon2" onClick={() => { setSearchInputValue(''); setGlobalFilterSearch('') }}><FaTimes /></span>
              </div>
            </div>
            <div className="col-sm-1">
              <MDButton className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => globalSearchOnChange()}> <RiSearchLine className="erp-download-icon-btn" /> </MDButton>
            </div>
          </div> */}
          <Datatable data={data} columns={columns} freezeColumnCount={5} stateValue={searchInputValue} />

          {pageCount !== 1 ?
            <ReactPaginate
              className='erp_pagination'
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link erp-gb-button"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link erp-gb-button"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link erp-gb-button"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"} /> : null}
        </> : <Card id="NoRcrdId" >
          <Card.Body>No records found...</Card.Body>
        </Card>}


      </DashboardLayout>
      <DeleteModal show={show} closeModal={handleClose} deleteRecord={DeleteSupplier} />
    </>

  )
}
